import React, { Component } from 'react'
import Switch from 'react-switch'

export default class SignatureForm extends Component {
  state = {
    name: '',
    title: '',
    email: '',
    mobile: '',
    location: 'valkenswaard',
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState(
      {
        [name]: value,
      },
      () => this.props.handleFormData(this.state)
    )
  }

  keyPressChange = event => {
    const regex = /^[0-9\b]+$/

    if (event.target.value === '' || regex.test(event.target.value)) {
      return true
    } else {
      return false
    }
  }

  componentDidMount() {
    this.props.handleFormData(this.state)
  }

  render() {
    return (
      <form className="c-emailsignature-form">
        <label>Name</label>
        <input
          type="text"
          name="name"
          value={this.state.name}
          onChange={this.handleInputChange}
        />
        <label>Title</label>
        <input
          type="text"
          name="title"
          value={this.state.title}
          onChange={this.handleInputChange}
        />
        <label>E-mail address</label>
        <input
          type="text"
          name="email"
          value={this.state.email}
          onChange={this.handleInputChange}
        />
        <label>Mobile phone (optional)</label>
        <input
          type="text"
          name="mobile"
          value={this.state.mobile}
          onChange={this.handleInputChange}
        />
        <label>Location</label>
        <select
          name="location"
          value={this.state.location}
          onChange={this.handleInputChange}
        >
          <option value="valkenswaard">Valkenswaard, The Netherlands</option>
          <option value="warshaw">Warsaw, Poland</option>
        </select>
      </form>
    )
  }
}
