/* eslint-disable */
import React, { Component } from 'react'
// import { parsePhoneNumberFromString } from 'libphonenumber-js
import * as libphonenumber from 'libphonenumber-js'

export default class SignatureDesign extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const phoneNumber = new libphonenumber.AsYouType('NL')
    phoneNumber.input(this.props.mobile)

    let phoneUri = ''

    if (phoneNumber.getNumber() !== undefined) {
      phoneUri = phoneNumber.getNumber().getURI()
    }

    const head = '<!DOCTYPE html>'
    const css = `
      .im,.aBn{-ms-text-size-adjust:100%!important;-webkit-text-size-adjust:100%!important}div[style*="margin: 16px 0"]{margin:0!important}table,td{mso-table-lspace:0!important;mso-table-rspace:0!important}img{-ms-interpolation-mode:bicubic}a{text-decoration:none}.aBn,.unstyle-auto-detected-links a,a[x-apple-data-detectors]{border-bottom:0!important;cursor:default!important;color:inherit!important;text-decoration:none!important;font-size:inherit!important;font-family:inherit!important;font-weight:inherit!important;line-height:inherit!important}.a6S{display:none!important;opacity:.01!important}.im{color:inherit!important;}img.g-img+div{display:none!important}@media only screen and (min-device-width:320px) and (max-device-width:374px){u~div .email-container{min-width:320px!important}}@media only screen and (min-device-width:375px) and (max-device-width:413px){u~div .email-container{min-width:375px!important}}@media only screen and (min-device-width:414px){u~div .email-container{min-width:414px!important}}
    `

    return (
      <React.Fragment>
        <html ref={this.props.signatureRef}>
          <head>
            <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
            <meta name="viewport" content="width=device-width" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="x-apple-disable-message-reformatting" />
            <style>{css}</style>
          </head>
          <body>
            <table
              style={{
                border: '2px solid #C8C8C8',
                borderCollapse: 'separate',
                padding: '36px 20px 20px 15px',
              }}
              width="383"
              className="email-container"
              bgcolor="#c8c8c8"
              ref={element => {
                if (element) {
                  element.style.setProperty(
                    'background',
                    '#c8c8c8',
                    'important'
                  )
                }
              }}
            >
              <tbody>
                <tr>
                  <td valign="top">
                    <table border="0" cellPadding="0" cellSpacing="0">
                      <tbody>
                        <tr>
                          <td valign="top" width="110">
                            <table
                              align="center"
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      textAlign: 'center',
                                      paddingTop: '4px',
                                    }}
                                  >
                                    <img
                                      alt="Logo"
                                      height="auto"
                                      src="https://abstraction.games/static/images/signature/logo-abstraction.png"
                                      style={{
                                        outline: 'none',
                                        textDecoration: 'none',
                                        border: 'none',
                                      }}
                                      width="77"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td style={{ textAlign: 'center' }} width="40">
                            &nbsp;
                          </td>
                          <td valign="center" width="330">
                            <table
                              align="center"
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      fontFamily:
                                        'arial, helvetica, sans-serif',
                                      fontSize: '14px',
                                      lineHeight: '14px',
                                      fontWeight: '400',
                                      color: '#000',
                                      paddingBottom: '8px',
                                    }}
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            className="im"
                                            style={{
                                              fontFamily:
                                                'arial, helvetica, sans-serif',
                                              fontSize: '16px',
                                              lineHeight: '22px',
                                              fontWeight: '700',
                                              color: '#000',
                                              WebkitFontSmoothing:
                                                'antialiased',
                                              MozOsxFontSmoothing: 'grayscale',
                                            }}
                                          >
                                            {this.props.name}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            className="im"
                                            style={{
                                              fontFamily:
                                                'arial, helvetica, sans-serif',
                                              fontSize: '12px',
                                              lineHeight: '17px',
                                              fontWeight: '400',
                                              color: '#000',
                                              WebkitFontSmoothing:
                                                'antialiased',
                                              MozOsxFontSmoothing: 'grayscale',
                                            }}
                                          >
                                            {this.props.title}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      fontFamily:
                                        'arial, helvetica, sans-serif',
                                      fontSize: '14px',
                                      lineHeight: '14px',
                                      fontWeight: '400',
                                      color: '#000',
                                    }}
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            className="im"
                                            style={{
                                              fontFamily:
                                                'arial, helvetica, sans-serif',
                                              fontSize: '11px',
                                              lineHeight: '17px',
                                              fontWeight: '400',
                                              color: '#000',
                                              textDecoration: 'underline',
                                              WebkitFontSmoothing:
                                                'antialiased',
                                              MozOsxFontSmoothing: 'grayscale',
                                            }}
                                          >
                                            <a
                                              href={`mailto:${
                                                this.props.email
                                              }`}
                                              style={{
                                                color: '#000',
                                                textDecoration: 'none',
                                              }}
                                            >
                                              {this.props.email}
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            className="im"
                                            style={{
                                              fontFamily:
                                                'arial, helvetica, sans-serif',
                                              fontSize: '11px',
                                              lineHeight: '17px',
                                              fontWeight: '400',
                                              color: '#000',
                                              textDecoration: 'underline',
                                              WebkitFontSmoothing:
                                                'antialiased',
                                              MozOsxFontSmoothing: 'grayscale',
                                            }}
                                          >
                                            <a
                                              href={`${phoneUri}`}
                                              style={{
                                                color: '#000',
                                                textDecoration: 'none',
                                              }}
                                            >
                                              {phoneNumber &&
                                              phoneNumber.getNumber() !==
                                                undefined
                                                ? phoneNumber
                                                    .getNumber()
                                                    .formatInternational()
                                                : ''}
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      fontFamily:
                                        'arial, helvetica, sans-serif',
                                      fontSize: '14px',
                                      lineHeight: '14px',
                                      fontWeight: '400',
                                      color: '#000',
                                      paddingTop: '5px',
                                    }}
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                    >
                                      <tbody>
                                        <tr
                                          style={{
                                            fontFamily:
                                              'Arial, helvetica, sans-serif',
                                            fontSize: '10px',
                                            lineHeight: '14px',
                                            fontWeight: '300',
                                            color: '#000',
                                            paddingBottom: '10px',
                                            WebkitFontSmoothing: 'antialiased',
                                            MozOsxFontSmoothing: 'grayscale',
                                            textDecoration: 'none',
                                          }}
                                        >
                                          <td
                                            width="170"
                                            className="aBn"
                                            style={{
                                              fontFamily:
                                                'Arial, helvetica, sans-serif',
                                              fontSize: '10px',
                                              lineHeight: '14px',
                                              fontWeight: '300',
                                              color: '#000',
                                              paddingTop: '10px',
                                              WebkitFontSmoothing:
                                                'antialiased',
                                              MozOsxFontSmoothing: 'grayscale',
                                              textDecoration: 'none',
                                            }}
                                            valign="top"
                                          >
                                            {this.props.location ===
                                              'valkenswaard' && (
                                              <React.Fragment>
                                                Deken Mandersplein 2-4
                                                <br />
                                                5554 HT Valkenswaard
                                                <br />
                                                The Netherlands
                                              </React.Fragment>
                                            )}
                                            {this.props.location ===
                                              'warshaw' && (
                                              <React.Fragment>
                                                Bonifraterska 17
                                                <br />
                                                #656, 00-203, Warsaw
                                                <br />
                                                Poland
                                              </React.Fragment>
                                            )}
                                          </td>
                                          <td
                                            style={{
                                              fontFamily:
                                                'Arial, helvetica, sans-serif',
                                              fontSize: '10px',
                                              lineHeight: '14px',
                                              fontWeight: '300',
                                              color: '#000',
                                              paddingTop: '10px',
                                              WebkitFontSmoothing:
                                                'antialiased',
                                              MozOsxFontSmoothing: 'grayscale',
                                              textDecoration: 'none',
                                            }}
                                            valign="top"
                                            width="120"
                                          >
                                            <table
                                              border="0"
                                              cellPadding="0"
                                              cellSpacing="0"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    className="im"
                                                    style={{
                                                      fontFamily:
                                                        'arial, helvetica, sans-serif',
                                                      fontSize: '10.1px',
                                                      lineHeight: '14px',
                                                      fontWeight: '400',
                                                      color: '#000',
                                                      textDecoration:
                                                        'underline',
                                                      WebkitFontSmoothing:
                                                        'antialiased',
                                                      MozOsxFontSmoothing:
                                                        'grayscale',
                                                    }}
                                                  >
                                                    {this.props.location ===
                                                      'valkenswaard' && (
                                                      <a
                                                        href="tel:+31407879990"
                                                        style={{
                                                          color: '#000',
                                                          textDecoration:
                                                            'none',
                                                        }}
                                                      >
                                                        +31 (0)40 78 79 990
                                                      </a>
                                                    )}
                                                    {this.props.location ===
                                                      'warshaw' && (
                                                      <a
                                                        href="tel:+48223325823"
                                                        style={{
                                                          color: '#000',
                                                          textDecoration:
                                                            'none',
                                                        }}
                                                      >
                                                        +48223325823
                                                      </a>
                                                    )}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="im"
                                                    style={{
                                                      fontFamily:
                                                        'arial, helvetica, sans-serif',
                                                      fontSize: '10.1px',
                                                      lineHeight: '14px',
                                                      fontWeight: '400',
                                                      color: '#000',
                                                      textDecoration:
                                                        'underline',
                                                      WebkitFontSmoothing:
                                                        'antialiased',
                                                      MozOsxFontSmoothing:
                                                        'grayscale',
                                                    }}
                                                  >
                                                    <a
                                                      href="https://abstraction.games/"
                                                      style={{
                                                        color: '#000',
                                                        textDecoration: 'none',
                                                        fontFamily:
                                                          'Arial, helvetica, sans-serif',
                                                        fontSize: '10.1px',
                                                        lineHeight: '14px',
                                                        fontWeight: '300',
                                                        color: '#000',
                                                      }}
                                                    >
                                                      www.abstraction.games
                                                    </a>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style={{
                                                      paddingTop: '10px',
                                                      paddingBottom: '2px',
                                                    }}
                                                  >
                                                    <table
                                                      border="0"
                                                      cellPadding="0"
                                                      cellSpacing="0"
                                                    >
                                                      <tbody>
                                                        <tr>
                                                          <td width="44">
                                                            <a
                                                              href="https://twitter.com/abstractiongame"
                                                              style={{
                                                                color:
                                                                  'inherit',
                                                                textDecoration:
                                                                  'none',
                                                              }}
                                                              target="_blank"
                                                            >
                                                              <img
                                                                alt="twitter"
                                                                height="22"
                                                                src="https://abstraction.games/static/images/signature/twitter.png"
                                                                style={{
                                                                  color:
                                                                    'inherit',
                                                                  textDecoration:
                                                                    'none',
                                                                }}
                                                                width="22"
                                                              />
                                                            </a>
                                                          </td>
                                                          <td width="44">
                                                            <a
                                                              href="https://www.youtube.com/channel/UCH10p-GpIrZ-WxPv0HZpwzQ"
                                                              style={{
                                                                color:
                                                                  'inherit',
                                                                textDecoration:
                                                                  'none',
                                                              }}
                                                              target="_blank"
                                                            >
                                                              <img
                                                                alt="youtube"
                                                                height="22"
                                                                src="https://abstraction.games/static/images/signature/youtube.png"
                                                                style={{
                                                                  color:
                                                                    'inherit',
                                                                  textDecoration:
                                                                    'none',
                                                                }}
                                                                width="22"
                                                              />
                                                            </a>
                                                          </td>
                                                          <td width="44">
                                                            <a
                                                              href="https://www.linkedin.com/company/abstraction-games-b-v-/"
                                                              style={{
                                                                color:
                                                                  'inherit',
                                                                textDecoration:
                                                                  'none',
                                                              }}
                                                              target="_blank"
                                                            >
                                                              <img
                                                                alt="linkedin"
                                                                height="22"
                                                                src="https://abstraction.games/static/images/signature/linkedin.png"
                                                                style={{
                                                                  color:
                                                                    'inherit',
                                                                  textDecoration:
                                                                    'none',
                                                                }}
                                                                width="22"
                                                              />
                                                            </a>
                                                          </td>
                                                          <td width="44">
                                                            <a
                                                              href="https://www.google.nl/maps/place/Abstraction+Games+B.V./@51.353554,5.4557884,17z/data=!3m1!4b1!4m22!1m16!4m15!1m6!1m2!1s0x47c6d711272906d1:0x5cb139e3474870b7!2sAbstraction+Games+B.V.!2m2!1d5.4579824!2d51.353554!1m6!1m2!1s0x47c6d711272906d1:0x5cb139e3474870b7!2sAbstraction+Games+B.V.,+Deken+Mandersplein+2-4,+5554+HT+Valkenswaard!2m2!1d5.4579824!2d51.353554!3e2!3m4!1s0x47c6d711272906d1:0x5cb139e3474870b7!8m2!3d51.353554!4d5.4579824"
                                                              style={{
                                                                color:
                                                                  'inherit',
                                                                textDecoration:
                                                                  'none',
                                                              }}
                                                              target="_blank"
                                                            >
                                                              <img
                                                                alt="map"
                                                                height="22"
                                                                src="https://abstraction.games/static/images/signature/map.png"
                                                                style={{
                                                                  color:
                                                                    'inherit',
                                                                  textDecoration:
                                                                    'none',
                                                                }}
                                                                width="22"
                                                              />
                                                            </a>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            &zwnj;
          </body>
        </html>
      </React.Fragment>
    )
  }
}
