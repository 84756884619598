import React, { Component } from 'react'
import Logo from './Logo'
import PrimaryNav from './PrimaryNav'
import Button from './Button'

export class Header extends Component {
  constructor(props) {
    super(props)
    this.state = { isToggleOn: true }
    this.toggleMenu = this.toggleMenu.bind(this)
    this.currentPath = props.currentPath
  }

  toggleMenu(e) {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn,
    }))
  }

  render() {
    return (
      <div className="c-header__inner">
        <Logo siteTitle={this.props.siteTitle} />
        <Button
          styleModifier="c-btn--small c-header__nav-btn"
          text={this.state.isToggleOn ? 'Menu' : 'Close'}
          onClickHandler={this.toggleMenu}
        />
        <div
          className={
            this.state.isToggleOn
              ? 'c-header__nav-container'
              : 'c-header__nav-container is-active'
          }
        >
          <PrimaryNav
            toggleStatus={this.toggleMenu}
            currentPath={this.currentPath}
          />
        </div>
      </div>
    )
  }
}

export default Header
