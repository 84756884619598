import React, { Component } from 'react'
import Link from 'gatsby-link'

export class PrimaryNavSubItem extends Component {
  constructor(props) {
    super(props)
    this.state = { isSubNavOn: false }
    if (this.props.isSubNavOn) {
      this.props.toggleNav()
    }
  }

  render() {
    return (
      <li className="c-primary-nav__subitem">
        <Link
          activeClassName={'is-active'}
          to={this.props.href}
          className="c-primary-nav__sublink"
        >
          <span className="c-primary-nav__text">{this.props.label}</span>
        </Link>
      </li>
    )
  }
}
export default PrimaryNavSubItem
