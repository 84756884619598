import React from 'react'
import PrimaryNavItem from './PrimaryNavItem'
import PrimaryNavDropdown from './PrimaryNavDropdown'
import PrimaryNavSubItem from './PrimarySubNavItem'

const PrimaryNav = ({ toggleStatus, currentPath }) => {
  return (
    <nav className="c-primary-nav" role="navigation">
      <ul
        className="c-primary-nav__list"
        role="tablist"
        aria-multiselectable="true"
      >
        <PrimaryNavItem
          href="/"
          label="Overview"
          key="introduction"
          toggleStatus={toggleStatus}
        />
        <PrimaryNavDropdown label="Story">
          {/* <PrimaryNavSubItem
            href="/story/"
            label="Overview"
            key="internal-branding"
            toggleStatus={toggleStatus}
            currentPath={currentPath}
          /> */}
          <PrimaryNavSubItem
            href="/story/golden-circle/"
            label="Golden circle"
            key="golden-circle"
            toggleStatus={toggleStatus}
            currentPath={currentPath}
          />
          <PrimaryNavDropdown
            label="Brand personality"
            key="brand-personality"
            subDropdown={true}
          >
            <PrimaryNavSubItem
              href="/story/brand-personality/"
              label="Overview"
              key="brand-personality"
              toggleStatus={toggleStatus}
              currentPath={currentPath}
            />
            <PrimaryNavSubItem
              href="/story/brand-values/"
              label="Brand values"
              key="brand-values"
              toggleStatus={toggleStatus}
              currentPath={currentPath}
            />
            <PrimaryNavSubItem
              href="/story/archetypes/"
              label="Archetypes"
              key="archetypes"
              toggleStatus={toggleStatus}
              currentPath={currentPath}
            />
            <PrimaryNavSubItem
              href="/story/bhag/"
              label="BHAG"
              key="bhag"
              toggleStatus={toggleStatus}
              currentPath={currentPath}
            />
            <PrimaryNavSubItem
              href="/story/brand-name/"
              label="Brand name"
              key="brand-name"
              toggleStatus={toggleStatus}
              currentPath={currentPath}
            />
          </PrimaryNavDropdown>
          <PrimaryNavSubItem
            href="/story/communication-model/"
            label="Communication model"
            key="communication-model"
            toggleStatus={toggleStatus}
            currentPath={currentPath}
          />
          <PrimaryNavSubItem
            href="/story/tone-of-voice/"
            label="Tone of voice"
            key="tone-of-voice"
            toggleStatus={toggleStatus}
            currentPath={currentPath}
          />
        </PrimaryNavDropdown>
        <PrimaryNavDropdown label="Design">
          <PrimaryNavSubItem
            href="/design/logo"
            label="Logo"
            key="logo"
            toggleStatus={toggleStatus}
            currentPath={currentPath}
          />
          <PrimaryNavSubItem
            href="/design/color/"
            label="Color"
            key="color"
            toggleStatus={toggleStatus}
            currentPath={currentPath}
          />
          <PrimaryNavSubItem
            href="/design/typography/"
            label="Typography"
            key="typography"
            toggleStatus={toggleStatus}
            currentPath={currentPath}
          />
          <PrimaryNavSubItem
            href="/design/visual-elements/"
            label="Visual elements"
            key="visual-elements"
            toggleStatus={toggleStatus}
            currentPath={currentPath}
          />
          <PrimaryNavSubItem
            href="/design/iconography/"
            label="Iconography"
            key="iconography"
            toggleStatus={toggleStatus}
            currentPath={currentPath}
          />
          <PrimaryNavSubItem
            href="/design/photography/"
            label="Photography"
            key="photography"
            toggleStatus={toggleStatus}
            currentPath={currentPath}
          />
        </PrimaryNavDropdown>
      </ul>
    </nav>
  )
}

export default PrimaryNav
