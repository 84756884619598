import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Index } from 'elasticlunr'

import { Link } from 'gatsby'

// Search component
export default class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
    }
  }

  render() {
    return (
      <div className="c-search__container o-layout__item u-5 u-offset-7">
        <input
          type="text"
          placeholder="Search within the brand guide.."
          value={this.state.query}
          onChange={this.search}
          className="c-search__input"
        />
        <ul
          className={
            this.state.query
              ? 'c-search__results is-active'
              : 'c-search__results'
          }
        >
          {this.state.results.map(page => {
            return (
              <li key={page.id}>
                <Link to={`/` + page.path}>{page.title}</Link>
              </li>
            )
          })}
          {this.state.results.length === 0 && (
            <li>
              No results found for query <b>"{this.state.query}" 😔</b>
            </li>
          )}
        </ul>
      </div>
    )
  }

  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex)

  search = evt => {
    const query = evt.target.value
    this.index = this.getOrCreateIndex()
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
  }
}

Search.propTypes = {
  searchIndex: PropTypes.object,
}
