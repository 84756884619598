import React, { Component } from 'react'
import { Link } from 'gatsby'

export default class PrimaryNavItem extends Component {
  constructor(props) {
    super()
    this.state = { isNavOn: true }
    this.currentPath = props.currentPath
  }

  render() {
    return (
      <li className="c-primary-nav__item">
        <Link
          activeClassName={'is-active'}
          to={this.props.href}
          className="c-primary-nav__link"
        >
          <span className="c-primary-nav__text">{this.props.label}</span>
        </Link>
      </li>
    )
  }
}
