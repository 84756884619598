import React, { Component } from 'react'
import Clipboard from 'react-clipboard.js'

import SignatureDesign from './SignatureDesign'

export default class SignatureResult extends Component {
  constructor(props) {
    super(props)

    this.state = { value: props, copied: false }

    this.signatureRef = React.createRef()

    this.onSuccess = this.onSuccess.bind(this)
    this.onSelect = this.onSelect.bind(this)
    this.getText = this.getText.bind(this)

    this.balloonProps = {
      'data-balloon-visible': false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.value !== nextProps) {
      this.setState({ value: nextProps })
    }
  }

  onSuccess() {
    this.setState({
      copied: true,
    })
    setTimeout(() => {
      this.setState({ copied: false })
    }, 1000)
  }

  getText(ref) {
    if (this.signatureRef) {
      return this.signatureRef.current.outerHTML
    } else {
      return null
    }
  }

  onSelect() {
    if (window.getSelection) {
      let range = document.createRange()
      range.selectNode(this.signatureRef.current)
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
      document.execCommand('copy')
      this.onSuccess()
    }
  }

  render() {
    return (
      <div className="c-emailsignature-result">
        <div className="c-emailsignature-result__inner">
          <div
            className="c-emailsignature-result__content"
            ref={r => (this.ref = r)}
          >
            <SignatureDesign
              name={this.state.value.formData.name}
              title={this.state.value.formData.title}
              mobile={this.state.value.formData.mobile}
              email={this.state.value.formData.email}
              location={this.state.value.formData.location}
              apple={this.state.value.formData.apple}
              signatureRef={this.signatureRef}
            />
          </div>
        </div>
        {this.state.copied && (
          <p className="c-emailsignature-result__copied">Copied!</p>
        )}
        <Clipboard
          className="c-btn"
          option-text={this.getText}
          onSuccess={this.onSuccess}
        >
          copy as html
        </Clipboard>
        <button className="c-btn" onClick={this.onSelect}>
          copy as select
        </button>
        <p className="c-emailsignature-result__p">
          <strong>Anti-aliasing depends on browser or email client</strong>
          <br />
          <br />
          <strong>Basic usage:</strong>
          <br />
          Click on "Copy as html" button and paste snippet of your signature
          into your email client settings.
          <br />
          <br />
          <strong>Advanced usage:</strong>
          <br /> For some email clients, like gmail, you may using simply
          copy/paste highlight selection. Click on "Copy as select" button and
          paste your signature into your email client settings.
          <br />
          <br />
          <strong>Apple Mail guide</strong>
          <br />
          Apple adjusts the layout when using the Copy as Select function.
          Unfortunately it doesn't allow html out of the box. Follow&nbsp;
          <a
            href="https://www.daretothink.co.uk/html-email-signature-in-apple-mail/"
            target="_blank"
          >
            this guide
          </a>
          &nbsp;to enable html in Apple mail.
        </p>
      </div>
    )
  }
}
