import React, { Component } from 'react'
export default class PrimaryNavDropdown extends Component {
  constructor(props) {
    super()
    this.state = {
      isNavOn: false,
      subDropdown: props.subDropdown,
      parentToggleNav: props.parentToggleNav,
    }
    this.toggleNav = this.toggleNav.bind(this)
  }

  toggleNav(child = false) {
    if (child) {
      if (this.state.isNavOn) return
    }
    this.setState(prevState => ({
      isNavOn: !prevState.isNavOn,
    }))
    if (this.state.parentToggleNav) {
      this.state.parentToggleNav(true)
    }
  }

  render() {
    if (this.props.children.length === 0) return
    const propChildren =
      this.props.children.length === undefined
        ? [this.props.children]
        : this.props.children
    const childrenWithExtraProp = propChildren.map(child => {
      return React.cloneElement(child, {
        isSubNavOn: child.props.currentPath === child.props.href,
        toggleNav: this.toggleNav,
        parentToggleNav: child.props.subDropdown ? this.toggleNav : null,
      })
    })
    return (
      <li
        className={
          this.state.isNavOn
            ? 'c-primary-nav__item is-active'
            : 'c-primary-nav__item'
        }
      >
        <div className="c-primary-nav__link" onClick={this.toggleNav}>
          <span className="c-primary-nav__text">{this.props.label}</span>
          <svg
            className="c-icon c-primary-nav__icon"
            width="16"
            height="8"
            viewBox="0 0 16 8"
            fill="none"
            id="chevron-right"
          >
            <title>chevron-right</title>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.99908 8C7.91745 8 7.83951 7.96868 7.78181 7.91371L0.0897601 0.504276C-0.02992 0.38903 -0.02992 0.201681 0.0897601 0.0864346C0.20944 -0.0288115 0.403997 -0.0288115 0.523677 0.0864346L7.99847 7.28666L15.4763 0.0864346C15.596 -0.0288115 15.7906 -0.0288115 15.9102 0.0864346C16.0299 0.201681 16.0299 0.38903 15.9102 0.504276L8.21573 7.91312C8.15804 7.96868 8.08071 8 7.99908 8Z"
              fill="#FFDF1D"
            />
          </svg>
        </div>
        <ul className="c-primary-nav__sublist">{childrenWithExtraProp}</ul>
      </li>
    )
  }
}
