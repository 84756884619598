import React from 'react'

const Footer = () => {
  return (
    <div className="c-footer o-layout o-layout--grids">
      <div className="o-layout__item u-10 u-6@large u-offset-1 u-offset-3@large">
        <a href="https://stuur.men" target="_blank" rel="noopener noreferrer">
          Stuurmen
        </a>
      </div>
    </div>
  )
}

export default Footer
