import React from 'react'
import Search from './Search'
import { StaticQuery, graphql } from 'gatsby'

const SearchHeader = () => (
  <StaticQuery
    query={graphql`
      query SearchIndexQuery {
        siteSearchIndex {
          index
        }
      }
    `}
    render={data => {
      return (
        <div className="c-search u-10 u-offset-1 o-layout__item o-layout o-layout--grids">
          <Search searchIndex={data.siteSearchIndex.index} />
        </div>
      )
    }}
  />
)

export default SearchHeader
