import React, { Component } from 'react'
import Layout from '../../components/Layout.js'
import SignatureForm from '../../components/emailSignature/SignatureForm'
import SignatureResult from '../../components/emailSignature/SignatureResult'

export default class EmailSignature extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formData: {},
      frontmatter: {
        title: 'E-mail Signature',
        description: 'Generate your e-mail signature here',
      },
    }
  }

  handleFormData(values) {
    this.setState({ formData: values })
  }

  render() {
    return (
      <Layout
        currentPath={this.props.location.pathname}
        frontmatter={this.state.frontmatter}
      >
        <div className="c-emailsignature__container o-layout o-layout--grids">
          <div className="o-layout__item u-10 u-4@xlarge u-offset-1">
            <SignatureForm handleFormData={this.handleFormData.bind(this)} />
          </div>
          <div className="o-layout__item u-10 u-7@xlarge">
            <SignatureResult formData={this.state.formData} />
          </div>
        </div>
      </Layout>
    )
  }
}
