import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './Header'
import '../styles/main.scss'
import PageHeader from './PageHeader'
import SearchHeader from './SearchHeader'
import Footer from './Footer'

const Layout = ({ children, currentPath, frontmatter }) => {
  if (!frontmatter) {
    return null
  }
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => (
        <div className="o-app o-layout o-layout--grids">
          <Helmet
            title={frontmatter.title + ' — ' + data.site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content: data.site.siteMetadata.description,
              },
            ]}
          >
            <html lang="en" />
            <meta name="robots" content="noindex" />
          </Helmet>
          <>
            <div className="c-header c-header--vertical o-layout__item u-3@large u-2@xxlarge">
              <Header
                siteTitle={data.site.siteMetadata.title}
                currentPath={currentPath}
              />
            </div>
            <div className="c-content o-layout__item u-9@large u-10@xxlarge u-offset-3@large u-offset-2@xxlarge o-layout o-layout--grids">
              <div className="c-content__header o-layout__item">
                <SearchHeader />
                <hr className="c-content__hr" />
                <PageHeader
                  title={frontmatter.title}
                  description={frontmatter.description}
                  currentPath={currentPath}
                />
              </div>
              <div className="o-layout__item u-12">
                {children}
                <Footer />
              </div>
            </div>
          </>
        </div>
      )}
    />
  )
}

export default Layout
