import React from 'react'

const PageHeader = ({ title, description }) => {
  return (
    <div className="c-page-header o-layout__item u-10 u-6@large u-offset-1 u-offset-3@large o-layout o-layout--grids">
      <h1 className="c-page-header__title o-layout__item u-12">{title}</h1>
      {description && (
        <div
          className="c-page-header__desc o-layout__item u-12"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </div>
  )
}

export default PageHeader
